import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as network from '../../services';
import * as pageActions from '../../store/actions/PageActions';
import * as userActions from '../../store/actions/UserActions';
import { Button, TextField, Typography, Grid, Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import AlertDialog from '../AlertDialog';
import i18next from 'i18next';
import PlayerInfo from './PlayerInfoDialog';
import { isMobile } from 'react-device-detect';
import VolumeUp from '@material-ui/icons/VolumeUp';
import VolumeDown from '@material-ui/icons/VolumeDown';
import { modeToName, prettiDateTime } from '../../services/utils';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

// import Slider from '@material-ui/lab/Slider';

const styles = theme => ({
    root: {
        // width: '100%',
        // marginTop: theme.spacing.unit * 3,
        paddingTop: '5px',
        overflowX: 'auto',
        marginBottom: '0.5rem',
        borderStyle: 'solid',
        borderColor: '#d1e4fc',
        minWidth: window.innerWidth
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
    error: {
        color: 'red'
    },
    formgroup: {
        paddingTop: '20px'
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    commentfield: {
        paddingBottom: '20px'
    },
    table: {
        paddingLeft: '20px',
        textAlign: 'left',
    },
    row: {
        paddingRight: '20px'
    },
    uptime: {
        fontVariant: 'all-petite-caps',
        fontStyle: 'italic',
        fontSize: 'small',
        textAlign: 'end',
        paddingTop: '1rem',

    },
    cardroot: {
        paddingBottom: '4px!important',
    },
    paperWidthSm: {
        maxWidth: "100%",
        margin: "0px"
    },
    infoSize: {
        fontSize: "14px",
    },
    infoSizeMobile: {
        fontSize: "10px",
    },
    formControl: {
        width: "90%",
        paddingTop: "0.4rem"
    },
    paperWidthSm: {
        width: isMobile ? window.innerWidth : window.innerWidth / 2,
        maxWidth: "650px",
        margin: "10px",
        minWidth: isMobile ? window.innerWidth - 50 : "650px"
    },
    selectedSettings: {
        paddingTop: '10px'
    }

});

class PlayerDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openInfo: false,
            fullscreen: true,
            alertUpdate: false,
            alertHomeapp: false,
            player: {
                name: '',
                comment: '',
                setings: this.props.setings,
                title_text: i18next.t("tittle_text"),
                alertDialog: false,
                alertAgree: false,
                mode: '',

            }
        };
    }

    handleVolumeSlider(e) {
        const id = this.props.user.role === 'listen' ? this.props.user.parent : this.props.user.id;
        const socket = network.socket;
        const mac = this.props.playerdata.mac;

        socket.emit('play', { 'volume': e.currentTarget.value, 'player': mac, 'ownid': id });

    }


    handleHomeApp() {
        const id = this.props.user.id;
        const socket = network.socket;
        const mac = this.props.playerdata.mac;

        socket.emit('play', { 'homeapp': 1, 'player': mac, 'ownid': id });
        this.setState({
            alertHomeapp: false
        });
    }

    handleAgreeHomeApp() {
        this.setState({
            alertHomeapp: true
        });
    }

    handleDelete = () => {
        this.setState({ alertDialog: true })
    }

    handleAgreeAlert = () => {
        const { handlePlayerDialogDelete, playerdata } = this.props;
        handlePlayerDialogDelete(playerdata)
        this.setState({ alertDialog: false, alertAgree: true })
    }

    handleCloseAlert = (data) => {
        const { handlePlayerDialog } = this.props;
        handlePlayerDialog.bind(this, data)
        this.setState({
            alertDialog: false,
            alertUpdate: false,
            alertHomeapp: false
        })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    };

    handleCickClose = () => {
        this.setState({ open: false })
    };

    handleInfoClose() {
        this.setState({ openInfo: false })
    }

    handleUpdatePlayer() {
        this.setState({
            alertUpdate: true
        })
    }

    handleAgreeUpdate() {
        const { updatePlayerApp } = this.props.pageActions;
        const { id } = this.props.user;
        const pid = this.props.playerdata.mac;
        updatePlayerApp(id, pid)
        this.setState({
            alertUpdate: false
        })
    }

    handleReloadPlayer() {
        const { reloadPlayer } = this.props.pageActions;
        const { id } = this.props.user;
        const pid = this.props.playerdata.mac;
        reloadPlayer(id, pid);
    }

    handleGetPlayerInfo() {
        const { getFullPlayerInfo } = this.props.pageActions;
        const { id } = this.props.user;
        const pid = this.props.playerdata.mac;
        getFullPlayerInfo(id, pid);
        this.setState({
            openInfo: true
        });
    }


    render() {
        const { open, classes, handlePlayerDialog, handlePlayerDialogSave, setings, playerdata, handlePlayerChangeSwitch, handlePlayerChangeText, pldialognotsave, audioVolume, titleMessage, handlePlayerChangeTitleMessage, listenerid, handleChange, handleChangeLogoPosition, handleChangePlayerSttings } = this.props;
        const { openInfo, mode, player } = this.state;
        const { dateNow, } = this.props.setings;
        const { logoPosition, showLogoDuringADV } = playerdata.setings;
        const { listeners, role } = this.props.user;
        const { latestVersion } = this.props.page;
        var data = {
            name: playerdata.name,
            comment: playerdata.comment,
            mac: playerdata.mac,
            setings: playerdata.setings
        };
        // data.setings.title_message = playerdata.title_message;

        return (

            <Dialog
                open={open}
                onClose={this.handleCickClose}
                aria-labelledby="form-dialog-title"
                // disableBackdropClick={false}
                classes={isMobile ? null : classes}
            // fullScreen={isMobile ? true : false}
            // classes={classes}
            >
                <DialogTitle id="form-dialog-title">{i18next.t("Player_settings")}</DialogTitle>
                <DialogContent>

                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="name"
                                label={i18next.t("Player_name")}
                                type="text"
                                fullWidth
                                // ref={(ref) => { this.playerName = ref; }}
                                onChange={role === 'listen' ? null : handlePlayerChangeText('name')}
                                value={playerdata.name}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>

                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                // autoFocus
                                margin="dense"
                                id="comment"
                                label={i18next.t("Comment")}
                                type="text"
                                fullWidth
                                // ref={(ref) => { this.playerComment = ref; }}
                                onChange={role === 'listen' ? null : handlePlayerChangeText('comment')}
                                className={classes.commentfield}
                                value={playerdata.comment}
                            />
                        </Grid>
                        {role === 'listen' ? null :
                            <Grid item xs={12} sm={6}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="listen-simple">{i18next.t("listen_user")}</InputLabel>
                                    <Select

                                        value={listenerid}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'listenerid',
                                            id: 'listen-simple',
                                        }}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>

                                        {listeners !== undefined ? listeners.map((v, key) => (
                                            <MenuItem key={key} value={v.username}>{v.username}</MenuItem>
                                        )) : null}

                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardroot}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {i18next.t("Player_info")}
                            </Typography>

                            <Grid container spacing={8}>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        {i18next.t("Totall")} - {setings.discspace} Mb
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        ID - {playerdata.id}
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        API - {setings.API}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        {i18next.t("Free")} - {setings.freediscspace} Mb
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        IP - {setings.ip}
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        {i18next.t("Version")} - {setings.VersionName}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        {i18next.t("Mode")} - {modeToName(this.props.user.modes, playerdata.mode)}
                                    </div>
                                </Grid>
                                {/* <Grid item xs>
                                    {i18next.t("Expires")} - {playerdata.expires}
                                </Grid> */}
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>
                                        {i18next.t("Time")} - {prettiDateTime(dateNow)}
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <div className={isMobile ? classes.infoSizeMobile : classes.infoSize}>

                                    </div>
                                </Grid>
                            </Grid>

                            <div className={classes.uptime}>{i18next.t("UpTime")}: {setings.UpTime}</div>

                        </CardContent>
                    </Card>
                    <div>
                        <DialogActions>
                            <VolumeDown />
                            <Tooltip title={<React.Fragment>
                                <div>Volume: {audioVolume !== -1 ? audioVolume : "disable"}</div>
                            </React.Fragment>}>
                                {audioVolume !== -1 ?
                                    <input type="range" min="0" max="15" id="volume" onChange={this.handleVolumeSlider.bind(this)} value={audioVolume} /> : <input type="range" disabled={true} />}
                            </Tooltip>
                            <VolumeUp />
                            {role === 'listen' ? null :
                                <Button className={classes.button} onClick={this.handleReloadPlayer.bind(this)} color="primary">Reload</Button>
                            }
                            {isMobile || role === 'listen' ? null : <div>
                                <Button className={classes.button} onClick={this.handleUpdatePlayer.bind(this)} color="primary"
                                    disabled={latestVersion === setings.VersionName}
                                >Update</Button>
                                <Button color="primary" className={classes.button} onClick={this.handleGetPlayerInfo.bind(this)}>Info</Button>
                                <Button color="primary" className={classes.button} onClick={this.handleAgreeHomeApp.bind(this)}>HomeAPP</Button>
                            </div>
                            }

                        </DialogActions>
                        <TextField
                            // autoFocus
                            margin="dense"
                            id="title_text"
                            label={i18next.t("Title_text_or_rss_link")}
                            type="text"
                            fullWidth
                            // ref={(ref) => { this.playerTitle_Message = ref; }}
                            onChange={handlePlayerChangeTitleMessage()}
                            className={classes.commentfield}
                            value={titleMessage}
                        />
                    </div>
                    <FormGroup row className={classes.formgroup}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.fs}
                                    onChange={handlePlayerChangeSwitch('fs')}
                                    value="fullscreen"
                                />
                            }
                            label={i18next.t("Progress_bar")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.deflogo}
                                    onChange={handlePlayerChangeSwitch('deflogo')}
                                    value="deflogo"
                                />
                            }
                            label={i18next.t("Default_logo")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.animateLogo}
                                    onChange={handlePlayerChangeSwitch('animateLogo')}
                                    value="animateLogo"
                                    disabled={setings.animateLogo === undefined}
                                />
                            }
                            label={i18next.t("animateLogo")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.dispid}
                                    onChange={handlePlayerChangeSwitch('dispid')}
                                    value="dispid"
                                />
                            }
                            label={i18next.t("Display_ID")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.title}
                                    onChange={handlePlayerChangeSwitch('title')}
                                    value="Title"
                                />
                            }
                            label={i18next.t("title")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.title_size}
                                    onChange={handlePlayerChangeSwitch('title_size')}
                                    value="title_size"
                                    disabled={setings.title_size !== undefined ? false : true}
                                />
                            }
                            label={i18next.t("title_size")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.title_back}
                                    onChange={handlePlayerChangeSwitch('title_back')}
                                    value="title_back"
                                    disabled={setings.title_back !== undefined ? false : true}
                                />
                            }
                            label={i18next.t("title_back")}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setings.isDemonApp}
                                    onChange={handlePlayerChangeSwitch('isDemonApp')}
                                    value="isDemonApp"
                                    disabled={setings.isDemonApp !== undefined ? false : true}
                                />
                            }
                            label={i18next.t("isDemonApp")}
                        />

                    </FormGroup>
                    <FormGroup className={classes.selectedSettings}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="logoposition">{i18next.t("logo_position")}</InputLabel>
                                    <Select
                                        value={logoPosition}
                                        disabled={logoPosition === undefined ? true : false}
                                        onChange={handleChangePlayerSttings}
                                        inputProps={{
                                            name: 'logoPosition',
                                            id: 'logoposition'
                                        }}
                                    >
                                        <MenuItem value="1">{i18next.t("left_up")}</MenuItem>
                                        <MenuItem value="2">{i18next.t("right_up")}</MenuItem>
                                        <MenuItem value="3">{i18next.t("right_down")}</MenuItem>
                                        <MenuItem value="4">{i18next.t("left_down")}</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="logoduringadv">{i18next.t("logo_during_adv")}</InputLabel>
                                    <Select
                                        value={showLogoDuringADV}
                                        disabled={showLogoDuringADV === undefined ? true : false}
                                        onChange={handleChangePlayerSttings}
                                        inputProps={{
                                            name: 'showLogoDuringADV',
                                            id: 'showLogoDuringADV'
                                        }}
                                    >
                                        <MenuItem value={true}>{i18next.t("Yes")}</MenuItem>
                                        <MenuItem value={false}>{i18next.t("No")}</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePlayerDialogSave.bind(this, data)}
                        color="primary" disabled={pldialognotsave}>
                        {i18next.t("Save")}
                    </Button>

                    <Button onClick={handlePlayerDialog.bind(this, data)} color="primary">
                        {i18next.t("Cancel")}
                    </Button>
                    {role === 'listen' ? null :
                        <Button onClick={this.handleDelete} color="primary">
                            {i18next.t("Delete")}
                        </Button>
                    }
                </DialogActions>
                <AlertDialog alert={'Delete Player'} message={'Confirm delete player!!!'} open={this.state.alertDialog} handleClose={this.handleCloseAlert} handleAgree={this.handleAgreeAlert} />
                <AlertDialog alert={'Warning'} message={'Configm_update_player'} open={this.state.alertUpdate} handleClose={this.handleCloseAlert} handleAgree={this.handleAgreeUpdate.bind(this)} />
                <AlertDialog alert={'Warning'} message={'Configm_homeapp_player'} open={this.state.alertHomeapp} handleClose={this.handleCloseAlert} handleAgree={this.handleHomeApp.bind(this)} />
                <PlayerInfo open={openInfo} id={playerdata.id} handleClose={this.handleInfoClose.bind(this)} />
            </Dialog>

        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default (connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlayerDialog)));