import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AddToQueue from '@material-ui/icons/AddToQueue';
import Tooltip from '@material-ui/core/Tooltip';
import NewPlaylistMenu from './NewPlaylistMenu';
import i18next from 'i18next';

const styles = theme => ({
    roothead: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
        marginBottom: '1rem',
        borderRadius: '4px',
        height: '3rem',
        alignItems: 'center'
    },
    type: {
        marginTop: '1rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
        fontSize: '0.8rem',
        flex: '0 0 auto',
    },
    spacer: {
        flex: '1 1 100%',
    },
    head: {
        marginTop: '0.5rem',
        marginLeft: '1rem',
        height: '1.5rem',
        fontSize: '0.8rem',
        flex: '0 0 auto',
    },
    button: {
        width: '1rem',
        // height: '2.4rem',
        borderRadius: '4px',
        minWidth: '2rem',
        // marginBottom: '0.2rem',
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
    },
    icon: {
        color: 'gray'
    }

});

class PlaylistFooter extends Component {

    render() {
        const { classes, handleChangePage, page } = this.props;

        return (
            <div className={classes.roothead}>
                <div className={classes.spacer} />
                <div className={classes.head}>{i18next.t("Page ") + page}</div>
                <Tooltip title="prev 10">
                    <Button className={classes.button} onClick={handleChangePage} id="minus">
                        <a style={{ color: '#4660e27d' }}> - </a>
                    </Button>
                </Tooltip>
                <Tooltip title="next 10">
                    <Button className={classes.button} onClick={handleChangePage} id="plus">
                        <a style={{ color: '#4660e27d' }}> + </a>
                    </Button>
                </Tooltip>

            </div>
        )
    }
}

export default withStyles(styles)(PlaylistFooter);