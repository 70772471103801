import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Content from './Playlists/content';
import * as pageActions from '../store/actions/PageActions';
import * as userActions from '../store/actions/UserActions';
import { withStyles } from '@material-ui/core/styles';
import * as network from '../services'
import UploadDialog from './Files/UploadDialog';
import AddStreamDialog from './Files/AddStreamDialog';
import FileList from './Files/files'
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import PlaylistShort from './Playlists/PlaylistShort';
import PlaylistsHeader from './Playlists/PlaylistsHeader';
import NewPlaylistDialog from './Playlists/NewPlaylistDialog';
import Players from './Players/Players';
import AddPacetsDialog from '../components/Playlists/AddPacetsDialog';
import i18next from 'i18next';
import AlertDialog from './AlertDialog';
import PlaylistsFooter from './Playlists/PlaylistsFooter';



const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '40%'
    },
    header: {
        display: 'flex',
        backgroundColor: '#d1e4fc',
        marginRight: '0.4rem',
        marginBottom: '1rem',
        height: '3rem',
        borderRadius: '4px'
    },
    header2: {
        // display: 'flex',
        backgroundColor: '#d1e4fc',
        // marginRight: '0.4rem',
        marginBottom: '1rem',
        height: '3rem',
        lineHeight: '3',
        color: '#737373',
        borderRadius: '4px',
        fontSize: '1rem',
        flex: '0 0 auto',
    },
    title: {
        //   flex: 'auto 0 auto',
        flex: '0 0 auto',
        marginLeft: '1rem',
        // marginTop: '1rem',
        marginBottom: '0.5rem',
        fontSize: '1rem',
        // fontWeight: '400',
        lineHeight: '3',
        color: '#737373',
        backgroundColor: '#d1e4fc'
    },
    paper: {
        display: 'block',
        overflow: 'scroll',
        //   padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        //   width: '100%',
        //   height: '100%',
        minWidth: '15.5rem',
        //   marginBottom: '1rem',
        //   marginRight: '1rem'
    },
    playlists: {
        display: 'block',
        overflow: 'scroll',
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%',
        paddingRight: '0.5rem',
        width: '100%',
    },
    content: {
        'text-align': 'center',
        fontSize: '20px',
        paddingTop: '25px'
    },
    contentplayer: {
        'text-align': 'center',
        paddingBottom: '38px',
        fontSize: '20px',
        paddingTop: '25px'
    },
    main: {
        display: 'flex',
        position: 'absolute',
        marginTop: '4.5rem',
        marginLeft: '0.5rem',
        width: '79%',
        height: '100%',
        minHeight: '15rem'
    },
    player: {
        flexBasis: '250px',
        flexShrink: '0',
        paddingRight: '10px',
        paddingLeft: '5px'
    },
    addicon: {
        // paddingLeft: '10px',
        color: 'gray',
        padding: 3,
    },
    players: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        minWidth: '30rem',
        // width: '47%'
    },
    mobile: {
        paddingTop: '4rem'
    },
    spacer: {
        flex: '1 1 100%',
    },
    tvicon: {
        alignSelf: 'center',
        // paddingRight: '0.5rem',
        width: '1.1rem'
    },
    gwicon: {
        alignSelf: 'center',
        // paddingRight: '0.5rem',
        color: 'gray',
        width: '1.1rem'
    },
    gwiconact: {
        alignSelf: 'center',
        // paddingRight: '0.5rem',
        color: 'Red',
        width: '1.1rem'
    },
    button: {
        // padding: 1,
        borderRadius: '4px',
        width: '1.1rem'
    },
});

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogopen: false,
            pldialogopen: false,
            playlistdialogopen: false,
            pldialognotsave: true,
            uploaded: '',
            progress: 0,
            uploadResult: '',
            screenWidth: window.innerWidth,
            isShowOffline: false,
            isGroupPlayers: false,
            sortPlaylists: 'all',
            packetDialogOpen: false,
            showWelcome: false,
            showWelcomeFisrtTime: true,
            addstreamopen: false,
            min: 0,
            max: 10,
            playlistOnPge: 10,
            search: "",
            isSearch: false,
        }
        this.handleDialog = this.handleDialog.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handlePlaylistDialogClose = this.handlePlaylistDialogClose.bind(this);
        this.handleStreamDialog = this.handleStreamDialog.bind(this);

    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    componentWillReceiveProps() {
        const { players, loaded } = this.props;

        if (players.length === 0 && loaded) {
            this.setState({
                showWelcome: this.state.showWelcomeFisrtTime ? true : false
            });
        } else {
            this.setState({
                showWelcome: false
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }


    setPlayListBtn(e) {
        this.props.getPosition();
    }

    handleDialog() {
        this.setState({
            dialogopen: !(this.state.gialogopen),
        });
    }

    handleStreamDialog() {
        this.setState({
            addstreamopen: !this.state.addstreamopen
        });
    }

    handleDialogClose() {
        this.setState({
            dialogopen: false
        });
    }

    handlePlaylistDialog(data) {
        // console.log('Playlist Dialog', data, this.state)
        this.setState({
            playlistdialogopen: !this.state.playlistdialogopen,
            playerplaylistdata: data
        });
    }
    handlePlaylistDialogClose() {
        this.setState({
            playlistdialogopen: !this.state.playlistdialogopen,
        })
    }

    deleteButton(e) {
        this.props.deleteContent(this.props.token, e.currentTarget.id)

    }

    editButton(name, data) {
        const { id } = this.props.user;
        this.props.renameContent(this.props.token, name, id)
    }

    handleAddAllPlaylistsToAllPlayers() {
        const { playlists } = this.props.page;

        const { id } = this.props.user;
        const socket = network.socket;
        var content = [];
        playlists.map(i => {
            content.push(i.id);
        });
        const data = { content: content, advertising: [] };
        socket.emit('play', { 'playlists': 1, 'player': 'all', 'data': data, 'ownid': id });
    }

    handleSortPlaylistByType(event) {
        // console.log(event.currentTarget.id)
        switch (event.currentTarget.id) {
            case 'adv':

                this.setState({ sortPlaylists: 'adv' });
                break;
            case 'cont':
                this.setState({ sortPlaylists: 'cont' });
                break;
            case 'pacs':
                this.setState({ sortPlaylists: 'pacs' });
                break;
            case 'all':
                this.setState({ sortPlaylists: 'all' });
                break;
            default:
                this.setState({ sortPlaylists: 'all' });
        };
    };

    handleChangePage(event) {
        switch (event.currentTarget.id) {
            case 'plus':
                console.log(">>>>>> SET PAGE: ", this.props.page.playlists.length, this.state.max)
                if (this.props.page.playlists.length <= this.state.max) break;
                this.setState({
                    min: this.state.min + this.state.playlistOnPge,
                    max: this.state.max + this.state.playlistOnPge,
                });
                break;
            case 'minus':
                if (this.state.min <= 0) break;
                this.setState({
                    min: this.state.min - this.state.playlistOnPge,
                    max: this.state.max - this.state.playlistOnPge,
                });
                break;
            default:
                break;
        }
    }


    handlePacketDialog() {
        const { token, getContent } = this.props;
        this.props.pageActions.getPacsPlaylists(token);
        this.setState({ packetDialogOpen: !this.state.packetDialogOpen });
        getContent(token);
    }

    handleWelcomeClose() {
        this.setState({
            showWelcome: false,
            showWelcomeFisrtTime: false,
        })
    }

    handleChangeSearch = event => {
        this.setState({ search: event.target.value });
    };

    handleSearch() {
        this.setState({
            isSearch: !this.state.isSearch,
            search: ""
        })
    }

    render() {
        const { content, classes, token, getContent, deleteContent, user, socket, isMobile } = this.props;
        const { showWelcome, search, isSearch } = this.state;
        const { dialogopen, pldialogopen, setings, playerdata, pldialognotsave, playlistdialogopen, playerplaylistdata, addstreamopen, isShowOffline, isGroupPlayers, packetDialogOpen } = this.state;
        const { players, playlists, pacsplaylists } = this.props.page;
        const { role, } = this.props.user;
        const { deletePlaylist, editPlaylist, createPlaylist, setPacsPlaylists, } = this.props.pageActions;
        const page = `${Math.ceil(this.state.max / this.state.playlistOnPge)}  / ${Math.ceil(playlists.length / this.state.playlistOnPge)}`
        content.map((d, i) => {
            return <Content data={d} index={i} key={i} deleteButton={this.deleteButton} token={token} getContent={getContent} editButton={this.editButton} deleteContent={deleteContent} />
        });

        if (isMobile) {
            return <div className={classes.mobile}>
                <Players socket={socket} isMobile={isMobile} />
            </div>
        } else {

            return <div className={classes.main}>

                <Players socket={socket} isMobile={isMobile} />
                <div className={classes.playlists} style={{ height: window.innerHeight - 50 }}>
                    <PlaylistsHeader handleDialog={this.handlePlaylistDialog.bind(this)} handleAddAllPlaylistsToAllPlayers={this.handleAddAllPlaylistsToAllPlayers.bind(this)} handleSortPlaylistByType={this.handleSortPlaylistByType.bind(this)} sortPlaylists={this.state.sortPlaylists} handlePacketDialog={this.handlePacketDialog.bind(this)} role={role} handleChangeSearch={this.handleChangeSearch.bind(this)} handleSearch={this.handleSearch.bind(this)} isSearch={isSearch} search={search} />

                    {playlists
                        .filter(playlist => {
                            if (search === "") {
                                return playlist
                            } else if (playlist.name.toLowerCase().includes(search.toLowerCase())) {
                                return playlist
                            }
                        })
                        .slice(this.state.min, this.state.max).map((playlist, key) => {

                            switch (this.state.sortPlaylists) {
                                case 'adv':
                                    if (playlist.type === "advertising") return (<div key={key} className={classes.paper}>
                                        <PlaylistShort playlist={playlist} />
                                    </div>)
                                    break;
                                case 'cont':
                                    if (playlist.type === "content") return (<div key={key} className={classes.paper}>
                                        <PlaylistShort playlist={playlist} key={key} />
                                    </div>)
                                    break;
                                case 'pacs':
                                    if (playlist.type === "pacs") return (<div key={key} className={classes.paper}>
                                        <PlaylistShort playlist={playlist} key={key} />
                                    </div>)
                                    break;
                                default:
                                    return (<div key={key} className={classes.paper}>
                                        <PlaylistShort playlist={playlist} key={key} />
                                    </div>)

                            }

                        })}
                    <PlaylistsFooter handleChangePage={this.handleChangePage.bind(this)} page={page} />

                </div>

                <FileList />
                <UploadDialog user={user} open={dialogopen} handleDialog={this.handleDialogClose} token={token} getContent={getContent} />
                <AddStreamDialog user={user} open={addstreamopen} handleDialog={this.handleStreamDialogClose} token={token} />
                <AddPacetsDialog open={packetDialogOpen} handlePacketDialog={this.handlePacketDialog.bind(this)} pacsplaylists={pacsplaylists} content={content} setPacsPlaylists={setPacsPlaylists} token={token} playlists={playlists} getContent={getContent} />

                <NewPlaylistDialog open={this.state.playlistdialogopen} handleNewPlaylistDialog={this.handlePlaylistDialog.bind(this)} createPlaylist={createPlaylist} token={token} role={role} playlists={playlists} />
                <AlertDialog open={showWelcome} alert='Info' message='welcome_message' handleAgree={this.handleWelcomeClose.bind(this)} />
            </div>
        }
    }
}

Page.propTypes = {
    players: PropTypes.array.isRequired,
    setPlayList: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,

}

function mapStateToProps(state) {
    return {
        user: state.user,
        page: state.page
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageActions: bindActionCreators(pageActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
}

export default DragDropContext(HTML5Backend)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Page)));